/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-slab-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
      url('../fonts/roboto-slab-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('../fonts/roboto-slab-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('../fonts/roboto-slab-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
      url('../fonts/roboto-slab-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
      url('../fonts/roboto-slab-v11-latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}

h1,h2,h3,h4
{
	font-family: roboto slab,serif !important;
}

h1 {
	font-size:34px;
	font-weight:bold;
	margin-left:0px;
}

hr {
	border-top:1.5px solid #72beda;
}

.navbar { background-color: #72beda; }

.nav a {
	color: black;
}

.navbar-brand h2 {
  display:inline;
  font-weight:bold;
  color:#3d3d3d;
  font-size:32px;
}

.navbar-brand img {
  width:50px;
  vertical-align: middle;
}

.navbar-expand-md .navbar-nav .nav-link { 
	color:black; 
}

.sidebar { font-size:16px; }

.list-group-item.active { background-color:#1695a9; border-color:#17a2b8; color:white; }

.post-entry 
  { margin-bottom:40px; padding-right:30px; }
.post-entry  img {
    width:200px;
  }
.post-entry  hr {
  border-top:1px solid grey;
  }

.nav-pills .nav-link.active, .nav-pills .show>.nav-link { background-color: #2381a5; }

@media screen and (max-width: 1024px){
    
    .nav {margin-top:10px;}
    nav .container {padding-left:0;}
}

.sidebar li.active {font-size:18px; font-weight:bold;}


.footer{
  background: #164f65;
  color:white;
  .links{
    ul {list-style-type: none;}
    li a{
      color: white;
      transition: color .2s;
      &:hover{
        text-decoration:none;
        color:#4180CB;
        }
    }
  }  
  .about-company{
    i {font-size: 25px;}
    a {
      color:white;
      transition: color .2s;
      &:hover{color:#4180CB}
    }
  } 
  .location{
    i{font-size: 18px;}
  }
  .copyright p {border-top:1px solid rgba(255,255,255,.1);} 
}

.fa { color:#006d7e; }

.l_int {
  text-indent: 25px;
}

.l_cont {
  margin-left:20px;
}


.c-body {
-webkit-box-flex: 1;
flex: 1 1 auto;
padding: 1.25rem;
}
.c-title {
  font-weight:bold;
}

.code-em {}

.i_int {
  text-indent: 30px;
}

.i_out {}

a.btlg:hover {
  text-decoration: none;
}
